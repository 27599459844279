"use client";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

import Link from "next/link";
import Button from "./Button";
import Logo from "./icons/Logo";
import { useEffect, useRef, useState } from "react";
import Image from "./Image";
import Facebook from "./icons/Facebook";
import LinkedIn from "./icons/LinkedIn";
import { usePathname, useRouter } from "next/navigation";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import If from "./If";

export default function Footer() {
  const Pathname = usePathname();
  const ref = useRef(null);
  const container = useRef(null);
  const bolt = useRef(null);
  const [p, setP] = useState("");

  const { observerRef, isIntersecting } = useIntersectionObserver({
    onIntersect: () => {
      // console.log("intersect");
    },
    onNotIntersect: () => {
      // console.log("not intersect");
    },
  });

  // useGSAP(
  //   () => {
  //     if (!gsap || !container.current) {
  //       return;
  //     }
  //     const tl = gsap.timeline({
  //       paused: true,
  //       scrollTrigger: {
  //         trigger: container.current,
  //         start: "top+=200 bottom",
  //         end: `bottom-=200 bottom`,
  //         scrub: 0.25,
  //       },
  //     });

  //     tl.to(bolt.current, {
  //       opacity: 0,
  //       filter: "blur(100px)",
  //       scale: 10,
  //       ease: "power3.inOut",
  //     });

  //     tl.to(ref.current, {
  //       opacity: 1,
  //       ease: "power3.inOut",
  //       delay: "-=0.5",
  //     });

  //     setTimeout(() => {
  //       tl?.scrollTrigger?.refresh();

  //       setTimeout(() => {
  //         tl?.scrollTrigger?.refresh();

  //         setTimeout(() => {
  //           tl?.scrollTrigger?.refresh();
  //         }, 2000);
  //       }, 2000);
  //     }, 2000);
  //   },
  //   {
  //     dependencies: [ref.current, container.current, p],
  //   }
  // );

  // useEffect(() => {
  //   setP(Pathname);
  //   setTimeout(() => {
  //     ScrollTrigger?.refresh();

  //     setTimeout(() => {
  //       ScrollTrigger?.refresh();

  //       setTimeout(() => {
  //         ScrollTrigger?.refresh();
  //       }, 2000);
  //     }, 2000);
  //   }, 2000);
  // }, [Pathname]);

  return (
    <>
      <footer
        className="relative bg-dark-blue pt-12 md:pt-32 pb-4 px-gutter"
        ref={container}
        data-intersect={isIntersecting}
      >
        <div className="w-[1200px] max-w-full mx-auto py-12 flex justify-between items-between flex-col md:flex-row gap-24 md:gap-40">
          <div className=" flex-half relative">
            {/* <div ref={ref} className="opacity-0">
              <LogoF blur />
            </div> */}
            <LogoF />
          </div>
          <div className="stack gap-8 flex-half" ref={observerRef}>
            <h2 className="text-h3 uppercase text-white font-hero font-medium leading-tight">
              Powering the future takes a team. Do you have what it takes?
            </h2>
            <Button
              size="small"
              link="/join-our-team"
              className="w-max"
              color="yellow-solid"
            >
              Join Our Team
            </Button>
          </div>
        </div>
        <div className="w-[1200px] max-w-full mx-auto py-12 flex flex-col md:flex-row justify-between items-between gap-10 md:gap-40">
          <div className="flex gap-4 text-white flex-half">
            <span className="text-sm">&copy; Lighthouse Electric</span>
            <Link className="text-sm" href="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <div className="gap-6 flex-half flex text-white">
            <a
              href="https://www.linkedin.com/company/lighthouse-electric-company-inc-"
              target="_blank"
              rel="noreferrer noopener"
            >
              <LinkedIn />
            </a>
            <a
              href="https://www.facebook.com/LighthouseElectric"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Facebook />
            </a>
          </div>
        </div>
      </footer>
      {/* <svg
        width="16"
        height="53"
        viewBox="0 0 16 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fixed right-4 bottom-4 z-[100] bolt"
        ref={bolt}
      >
        <path
          d="M7.89505 2.33923e-07L7.89503 15.9L13.2466 21.5533L2.99998 30.0333L7.89504 36.04L7.89504 53"
          stroke="#EEE809"
          stroke-width="3"
        />
      </svg> */}
      <div
        className={`fixed right-2 bottom-2 z-50 ${
          isIntersecting ? "opacity-0" : "opacity-100"
        } transition-all duration-300 ease-in-out`}
        id="join-our-team"
      >
        <Button size="small" color="yellow-solid" link="/join-our-team">
          Join Our Team
        </Button>
      </div>
    </>
  );
}

const LogoF = ({ blur = false }) => (
  <svg
    width="300"
    viewBox="0 0 439 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`max-w-[90dvw] ${
      blur ? "filter blur-lg absolute z-0" : "relative z-[2]"
    }`}
  >
    <g clipPath="url(#clip0_32_20)">
      <path
        d="M61.1313 32.5488V12.781H55.9495V36.7208H72.6557L71.0518 32.5488H61.1313Z"
        fill="#FFFFFF"
      />
      <path d="M81.0088 12.781H75.827V36.7208H81.0088V12.781Z" fill="#FFFFFF" />
      <path
        d="M123.044 22.6603L114.092 22.6649V12.781H108.91V36.7208H114.092V26.8323H123.044V36.7208H128.226V12.781H123.044V22.6603Z"
        fill="#FFFFFF"
      />
      <path
        d="M166.162 22.6603H157.21V12.781H152.029V36.7208H157.21V26.8323H166.162V36.7208H171.344V12.781H166.162V22.6603Z"
        fill="#FFFFFF"
      />
      <path
        d="M211.976 32.5443H203.838V12.781H198.656V33.5678L201.827 36.7208H213.996L217.158 33.5678V12.781H211.976V32.5443Z"
        fill="#FFFFFF"
      />
      <path
        d="M88.4891 12.781L85.3179 15.934V33.5678L88.4891 36.7208H101.7L104.871 33.5678V23.1767H96.6549L94.7997 27.3807H99.6936V32.5443L90.4997 32.5488V18.6437L88.512 16.9576H99.6891V20.0237H104.871V15.934L101.759 12.781H88.4891Z"
        fill="#FFFFFF"
      />
      <path
        d="M132.032 16.953H137.753V36.7208H142.935V18.6437L140.938 16.9576H148.314L150.123 12.781H130.223L132.032 16.953Z"
        fill="#FFFFFF"
      />
      <path
        d="M178.408 12.781L175.237 15.934V33.5678L178.408 36.7208H191.153L194.324 33.5678V15.934L191.153 12.781H178.408ZM189.142 32.5443H180.424V18.6392L178.431 16.953H189.147V32.5443H189.142Z"
        fill="#FFFFFF"
      />
      <path
        d="M226.512 22.6603V18.6437L224.52 16.953H235.327V19.7039H240.513V15.934L237.342 12.781H224.515L221.344 15.934V23.9078L224.159 26.8323H235.327V32.5443H226.512V29.8299H221.344V33.5678L224.52 36.7208H237.356L240.513 33.5678V25.1827L237.991 22.6603H226.512Z"
        fill="#FFFFFF"
      />
      <path
        d="M244.786 36.7208H263.269V32.5488H249.967V26.8323H262.419V22.6603H249.967V18.6437L247.984 16.953H263.269V12.781H244.786V36.7208Z"
        fill="#FFFFFF"
      />
      <path
        d="M305.305 32.5488V12.781H300.123V36.7208H316.833L315.23 32.5488H305.305Z"
        fill="#FFFFFF"
      />
      <path d="M414.8 12.781H409.618V36.7208H414.8V12.781Z" fill="#FFFFFF" />
      <path
        d="M346.202 12.781L343.031 15.934V33.5678L346.202 36.7208H359.412L362.579 33.5678V29.2907H357.397V32.5443H348.213V18.6437L346.216 16.953H357.402V20.0237H362.584V15.934L359.412 12.781H346.202Z"
        fill="#FFFFFF"
      />
      <path
        d="M422.618 12.781L419.447 15.934V33.5678L422.618 36.7208H435.829L439 33.5678V29.2907H433.818V32.5443H424.629V18.6437L422.641 16.953H433.818V20.0237H439V15.934L435.838 12.781H422.618Z"
        fill="#FFFFFF"
      />
      <path
        d="M365.728 16.9576H371.449V36.7208H376.63V18.6437L374.647 16.9576H382.009L383.818 12.781H363.918L365.728 16.9576Z"
        fill="#FFFFFF"
      />
      <path
        d="M277.951 36.7208H296.431V32.5488H283.133V26.8323H295.585V22.6603H283.133V18.6437L281.145 16.953H296.431V12.781H277.951V36.7208Z"
        fill="#FFFFFF"
      />
      <path
        d="M320 36.7208H338.484V32.5488H325.182V26.8323H337.634V22.6603H325.182V18.6437L323.199 16.953H338.484V12.781H320V36.7208Z"
        fill="#FFFFFF"
      />
      <path
        d="M386.057 12.781L385.998 36.7208H391.189V28.3677H396.841L399.693 36.7208H405.167L402.252 28.1758C403.344 27.0837 404.02 26.4074 405.167 25.2604V15.934L402.01 12.781H386.057ZM389.197 16.953H399.976V24.1637H391.189V18.6392L389.197 16.953Z"
        fill="#FFFFFF"
      />
      <path
        d="M29.7249 13.0597L29.2816 15.8243L27.8148 16.2492L28.6053 27.4903L47.4912 12.9546L29.7249 13.0597Z"
        fill="#FFFFFF"
      />
      <path
        d="M35.775 0H18.0817L2.99768 25.1325H15.2258L6.10352e-05 49.5018L18.8722 34.9798L20.0649 18.0314L25.3473 15.774H18.9454L18.493 12.9501H20.7686V9.54119L23.1082 7.8459L24.0038 5.77133L24.8858 7.81848L27.5087 9.53662V11.1725L35.775 0Z"
        fill="#FFFFFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_32_20">
        <rect width="439" height="49.5018" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
