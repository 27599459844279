"use client";
import { ReactLenis, useLenis } from "lenis/react";
import { usePathname, useSearchParams } from "next/navigation";
import { Suspense, useEffect } from "react";

const OnChange = () => {
  return null;
};

function SmoothScrolling({ children }) {
  const pathname = usePathname();
  const lenis = useLenis();
  useEffect(() => {
    if (window.location.hash) {
      lenis?.scrollTo(window.location.hash, { immediate: true });
      return;
    }
    console.log("I am doing this");
    lenis?.scrollTo(0, { immediate: true });
  }, [pathname]);

  return (
    <ReactLenis root options={{ lerp: 0.1, duration: 1.5, smoothWheel: true }}>
      {children}
    </ReactLenis>
  );
}

export default SmoothScrolling;
