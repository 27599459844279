export default function Logo({
  color = "black",
  width = "300",
  className = "",
}) {
  return (
    <>
      <svg
        className={`${className}`}
        viewBox="0 0 315 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2315_27)">
          <path
            d="M43.8637 23.3551V9.1709H40.1455V26.3487H52.1329L50.982 23.3551H43.8637Z"
            fill="white"
          />
          <path
            d="M58.1264 9.1709H54.4082V26.3487H58.1264V9.1709Z"
            fill="white"
          />
          <path
            d="M88.2889 16.2597L81.8656 16.263V9.1709H78.1475V26.3487H81.8656V19.2533H88.2889V26.3487H92.007V9.1709H88.2889V16.2597Z"
            fill="white"
          />
          <path
            d="M119.227 16.2597H112.804V9.1709H109.086V26.3487H112.804V19.2533H119.227V26.3487H122.946V9.1709H119.227V16.2597Z"
            fill="white"
          />
          <path
            d="M152.101 23.3518H146.261V9.1709H142.543V24.0863L144.818 26.3487H153.55L155.819 24.0863V9.1709H152.101V23.3518Z"
            fill="white"
          />
          <path
            d="M63.4943 9.1709L61.2188 11.4333V24.0863L63.4943 26.3487H72.9733L75.2488 24.0863V16.6302H69.3535L68.0223 19.6467H71.5339V23.3518L64.9369 23.3551V13.3776L63.5106 12.1677H71.5307V14.3678H75.2488V11.4333L73.016 9.1709H63.4943Z"
            fill="white"
          />
          <path
            d="M94.7379 12.1645H98.8429V26.3487H102.561V13.3776L101.128 12.1677H106.42L107.719 9.1709H93.4395L94.7379 12.1645Z"
            fill="white"
          />
          <path
            d="M128.015 9.1709L125.739 11.4333V24.0863L128.015 26.3487H137.159L139.435 24.0863V11.4333L137.159 9.1709H128.015ZM135.717 23.3518H129.461V13.3744L128.031 12.1645H135.72V23.3518H135.717Z"
            fill="white"
          />
          <path
            d="M162.531 16.2597V13.3776L161.101 12.1645H168.855V14.1383H172.577V11.4333L170.301 9.1709H161.098L158.822 11.4333V17.1548L160.842 19.2533H168.855V23.3518H162.531V21.4042H158.822V24.0863L161.101 26.3487H170.311L172.577 24.0863V18.0696L170.767 16.2597H162.531Z"
            fill="white"
          />
          <path
            d="M175.643 26.3487H188.905V23.3551H179.361V19.2533H188.296V16.2597H179.361V13.3776L177.938 12.1645H188.905V9.1709H175.643V26.3487Z"
            fill="white"
          />
          <path
            d="M219.068 23.3551V9.1709H215.35V26.3487H227.34L226.189 23.3551H219.068Z"
            fill="white"
          />
          <path
            d="M297.635 9.1709H293.917V26.3487H297.635V9.1709Z"
            fill="white"
          />
          <path
            d="M248.413 9.1709L246.138 11.4333V24.0863L248.413 26.3487H257.892L260.165 24.0863V21.0173H256.446V23.3518H249.856V13.3776L248.423 12.1645H256.45V14.3678H260.168V11.4333L257.892 9.1709H248.413Z"
            fill="white"
          />
          <path
            d="M303.245 9.1709L300.97 11.4333V24.0863L303.245 26.3487H312.724L315 24.0863V21.0173H311.282V23.3518H304.688V13.3776L303.262 12.1645H311.282V14.3678H315V11.4333L312.731 9.1709H303.245Z"
            fill="white"
          />
          <path
            d="M262.423 12.1677H266.528V26.3487H270.247V13.3776L268.824 12.1677H274.106L275.404 9.1709H261.125L262.423 12.1677Z"
            fill="white"
          />
          <path
            d="M199.44 26.3487H212.7V23.3551H203.159V19.2533H212.093V16.2597H203.159V13.3776L201.732 12.1645H212.7V9.1709H199.44V26.3487Z"
            fill="white"
          />
          <path
            d="M229.612 26.3487H242.875V23.3551H233.331V19.2533H242.265V16.2597H233.331V13.3776L231.907 12.1645H242.875V9.1709H229.612V26.3487Z"
            fill="white"
          />
          <path
            d="M277.011 9.1709L276.969 26.3487H280.694V20.355H284.749L286.795 26.3487H290.723L288.632 20.2173C289.415 19.4336 289.9 18.9484 290.723 18.1254V11.4333L288.458 9.1709H277.011ZM279.264 12.1645H286.999V17.3385H280.694V13.3744L279.264 12.1645Z"
            fill="white"
          />
          <path
            d="M21.3286 9.37082L21.0105 11.3545L19.958 11.6594L20.5252 19.7254L34.0766 9.29541L21.3286 9.37082Z"
            fill="white"
          />
          <path
            d="M25.6699 0H12.9743L2.15091 18.0335H10.925L0 35.5195L13.5415 25.0994L14.3973 12.9382L18.1876 11.3185H13.594L13.2694 9.29219H14.9023V6.84619L16.581 5.62974L17.2237 4.14116L17.8565 5.61007L19.7385 6.84291V8.01673L25.6699 0Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2315_27">
            <rect width="315" height="35.5195" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {/* <svg
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <rect width="100" height="100" fill="black" />
        <g clip-path="url(#clip0_260_1781)">
          <path
            d="M58.0305 33.4638L57.4027 37.3749L55.3333 37.9777L56.4499 53.8721L83.1582 33.3193L58.0305 33.4638Z"
            fill="currentColor"
          />
          <path
            d="M66.5861 15H41.5667L20.2389 50.5389H37.5333L16 85L42.6889 64.4611L44.3722 40.5L51.8417 37.3083H42.7917L42.15 33.3111H45.3667V28.4889L48.6778 26.0972L49.9417 23.1639L51.1861 26.0528L54.8972 28.4861V30.8028L66.5861 15Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_260_1781">
            <rect
              width="67.1583"
              height="70"
              fill="currentColor"
              transform="translate(16 15)"
            />
          </clipPath>
        </defs>
      </svg> */}
    </>
  );
}
